<template>
  <div v-if="selectedSearch" id="index-search-setup" class="vap-page">
    <div class="inner-div">
      <div class="vap-page-header">
        <VasionArrowLeftIcon class="left vasion-cursor back-arrow" @click="backToSearchSetup" />
        <h1 id="section-headline"> {{ searchName }} </h1>
        <div class="btns-container">
          <VasionButton
            id="date-options-button"
            class="action-buttons"
            name="date-options-button"
            classProp="text"
            @vasionButtonClicked="launchDateFields()"
          >
            DATE OPTIONS
          </VasionButton>
          <VasionButton
            id="static-fields-button"
            class="action-buttons"
            name="static-fields-button"
            classProp="text"
            :isDisabled="applyToIndexFields === 'all'"
            @vasionButtonClicked="launchStaticFields()"
          >
            STATIC FIELDS
          </VasionButton>
          <VasionButton
            id="cancel-button"
            class="action-buttons"
            name="cancel-button"
            classProp="text"
            @vasionButtonClicked="backToSearchSetup"
          >
            CANCEL
          </VasionButton>
          <VasionButton
            id="add-button"
            class="action-buttons last-btn"
            name="add-button"
            classProp="primary"
            :isDisabled="!canSave"
            @vasionButtonClicked="saveIndexSearch"
          >
            {{ addButtonText }}
          </VasionButton>
        </div>
      </div>
      <table class="w100">
        <tr>
          <td>
            <VasionInput
              id="search-name"
              v-model="searchName"
              class="search-name"
              title="SEARCH NAME"
              name="search-name"
              placeholder="Search Name..."
              inputType="top-white"
              width="100%"
              required
              @input="setIsDirty"
            />
          </td>
          <td>
            <VasionDropList
              v-slot="slotItem"
              v-model="selectedCustomizeFolder"
              :dataArray="folderSearchConfigurations"
              width="100%"
              title="CUSTOM FOLDER VIEW"
              placeholder="Customize Folders..."
              type="plain-list"
              displayName="name"
              valueName="id"
              @input="setIsDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </td>
        </tr>
      </table>
      <div class="setup-row">
        <div id="table-label-pii">
          <span id="table-label">
            SELECT {{ $formsLabel.toUpperCase() }}(S) TO SEARCH *
          </span>
          <VasionCheckbox
            id="pii-data"
            class="iss-vasion-checkbox"
            name="pii-data"
            :checked="piiData"
            @change="togglePiiData(); setIsDirty()"
          >
            Contains PII Data
          </VasionCheckbox>
          <VasionCheckbox
            id="contains-search"
            class="iss-vasion-checkbox"
            name="contains-search"
            :checked="containsSearch"
            @change="containsSearch = !containsSearch; setIsDirty()"
          >
            Contains Search
          </VasionCheckbox>
        </div>
        <div v-if="searchFormsDataTable && searchFormsDataTable.Columns" class="main-table-container vasion-html-table-default">
          <VasionTable
            id="search-forms"
            v-model="indexFormIDs"
            :headerColumns="searchFormsDataTable.Columns.Values"
            :tableRows="searchFormsDataTable.Rows.Values"
            :hideColumns="searchFormsHideColumns"
            @vasion-row-checked="handleSearchFormRowCheckChange"
          />
        </div>
      </div>

      <div v-if="indexFormIDs && indexFormIDs.length > 0" class="setup-row">
        <hr>
        <table class="w100">
          <tr>
            <td id="apply-fields" :class="{'two-columns': !textractLineItemDataVisible || applyToIndexFields !== 'specific'}">
              <div>
                <md-radio
                  v-model="applyToIndexFields"
                  value="all"
                  @change="setIsDirty"
                >
                  Apply on {{ $fieldsLabelPlural.toLowerCase() }}
                </md-radio>
              </div>
              <div>
                <md-radio
                  v-model="applyToIndexFields"
                  value="specific"
                  @change="setIsDirty"
                >
                  Apply on specific fields
                </md-radio>
              </div>
            </td>
            <td id="full-text" v-if="textractLineItemDataVisible" :class="{'two-columns': applyToIndexFields !== 'specific'}">
              <VasionCheckbox
                id="search-lineitems"
                name="search-lineitems"
                :checked="searchLineItems"
                @change="toggleSearchLineItems(); setIsDirty()"
              >
                Search Line Items
              </VasionCheckbox>
              <VasionInput
                id="search-lineitems-label"
                v-model="searchLineItemsLabel"
                class="title-input"
                title="SEARCH LINE ITEMS LABEL"
                name="search-lineitems-label"
                placeholder="Search Line Items Label..."
                inputType="top-white"
                width="100%"
                :isDisabled="!searchLineItems"
                @input="setIsDirty"
              />
            </td>
            <td id="line-items" v-if="applyToIndexFields === 'specific'" :class="{'two-columns': !textractLineItemDataVisible}">
              <div>
                <VasionCheckbox
                  id="include-full-text-field"
                  name="include-full-text-field"
                  :checked="includeFullTextField"
                  @change="toggleIncludeFullTextField(); setIsDirty()"
                >
                  Include Additional Full Text Search
                </VasionCheckbox>

                <VasionInput
                  :isDisabled="!includeFullTextField"
                  id="title"
                  v-model="fullTextFieldTitle"
                  class="title-input"
                  title="FULL TEXT SEARCH LABEL"
                  name="title"
                  placeholder="Title..."
                  inputType="top-white"
                  @input="setIsDirty"
                />
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="setup-row">
        <h3>Please select {{ $formsLabelWithPrefix.toLowerCase() }}, above, to configure field search criteria</h3>
      </div>

      <div v-if="applyToIndexFields === 'specific' && searchFieldsDataTable && searchFieldsDataTable.Rows" class="setup-row">
        <div class="main-table-container vasion-html-table-default">
        <VasionTable
          id="search-fields"
          v-model="indexFieldIDs"
          :headerColumns="searchFieldsDataTable.Columns.Values"
          :tableRows="searchFieldsDataTable.Rows.Values"
          :hideColumns="searchFieldsHideColumns"
          @vasion-row-checked="handleSearchFieldRowCheckChange"
        />
      </div>
      </div>

      <div class="setup-row">
        <hr>
        <div id="apply-folders">
          <div>
            <md-radio
              v-model="applyToFolders"
              value="all"
              :disabled="includeFullTextField === true"
              @change="setIsDirty"
            >
              Apply on all folders
            </md-radio>
          </div>
          <div>
            <md-radio
              v-model="applyToFolders"
              value="specific"
              @change="setIsDirty"
            >
              Apply on specific folders
            </md-radio>
          </div>
          <div>
            <md-radio
              v-model="applyToFolders"
              value="sub"
              @change="setIsDirty"
            >
              Choose subfolder
            </md-radio>
          </div>
        </div>
        <div id="sub-folders">
          <div>
            <VasionCheckbox
              v-if="applyToFolders !== 'all'"
              id="include-selected-folder"
              name="include-selected-folder"
              :checked="searchSubfolders"
              @change="toggleSearchOnlyInSubfolders(); setIsDirty()"
            >
              Search Only in Selected Folder
            </VasionCheckbox>

            <VasionButton
              v-if="applyToFolders === 'sub'"
              id="choose-subfolder-button"
              name="choose-subfolder-button"
              classProp="primary"
              @vasionButtonClicked="toggleBrowseFolderDialog()"
            >
              {{ chooseSubFolderText }}
            </VasionButton>
          </div>
        </div>
      </div>
      <div class="setup-row margin-bottom-30">
        <div v-if="applyToFolders === 'specific' && searchFoldersDataTable && searchFoldersDataTable.Rows" class="main-table-container vasion-html-table-default">
          <!-- This should really be a modal... -->
          <VasionTable
            id="search-folders"
            v-model="searchInFolders"
            :headerColumns="searchFoldersDataTable.Columns.Values"
            :tableRows="searchFoldersDataTable.Rows.Values"
            :hideColumns="searchFoldersHideColumns"
            @vasion-row-checked="handleSearchFolderRowCheckChange"
          />
        </div>
      </div>
    </div>

    <VasionSnackbar
      id="index-search-setup-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

    <VasionGeneralModal
      id="static-fields-dialog"
      :modalType="'slot'"
      :hideButtons="true"
      :sync="showStaticFields"
      :modalTitle="'Static Fields'"
      @noButtonClick="showStaticFields = !showStaticFields"
    >
      <IndexSearchStaticFields
        :propAllIndexFields="staticSearchAvailableFields"
        :propCriteriaFields="staticFields"
        @okClick="staticFieldsOKClick"
        @cancelClick="staticFieldsCancelClick"
      />
    </VasionGeneralModal>

    <VasionGeneralModal 
      id="date-fields-dialog" 
      :modalType="'slot'"
      :hideButtons="true"
      :sync="showDateFields"
      :modalTitle="'Date Options'"
      @noButtonClick="showDateFields = !showDateFields"
    >
      <IndexSearchDateOptions
        :propAllDateFields="dateFieldOptionsAvailableFields"
        :propCriteriaFields="dateOptions"
        @okClick="dateFieldsOKClick"
        @cancelClick="dateFieldsCancelClick"
      />
    </VasionGeneralModal>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <md-dialog id="browse-folders-modal" :md-active.sync="showFolderBrowserDialog" :md-click-outside-to-close="false">
      <div class="folder-dialog-browse">
        <VasionFolders
          :toggleStorageConfigIdByDefault="null"
        />
      </div>
      <div class="align-right">
        <VasionButton
          id="folders-browser-cancel"
          classProp="secondary"
          :isRaised="true"
          @vasionButtonClicked="toggleBrowseFolderDialog()"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="folders-browser-ok"
          classProp="primary"
          :isRaised="true"
          @vasionButtonClicked="folderModalOK()"
        >
          OK
        </VasionButton>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import IndexSearchDateOptions from '@/components/search/IndexSearchDateOptions.vue';
import IndexSearchStaticFields from '@/components/search/IndexSearchStaticFields.vue';

export default {
  name: 'IndexSearchSetup',
  components: {
    IndexSearchDateOptions,
    IndexSearchStaticFields,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  props: {
    searchID: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: function () {
    return {
      applyToFolders: 'all',
      applyToIndexFields: 'all',
      containsSearch: false,
      dateFieldOptionsAvailableFields: [],
      dateOptions: [],
      flatCombinedFieldSet: {},
      folderID: null,
      folderName: '',
      fullTextFieldTitle: '',
      includeFullTextField: false,
      indexFieldIDs: [],
      indexFormIDs: [],
      isDirty: false,
      openInNativeFormat: false,
      piiData: false,
      primarySearchFieldIDs: [],
      searchFieldsDataTable: {},
      searchFieldsHideColumns: ['_FieldID_'],
      searchFoldersDataTable: {},
      searchFoldersHideColumns: ['_VaultID_'],
      searchFormsDataTable: {},
      searchFormsHideColumns: ['_FormID_'],
      searchInFolders: [],
      searchLineItems: false,
      searchLineItemsLabel: '',
      searchName: '',
      searchSubfolders: false,
      selectedCustomizeFolder: {},
      showDateFields: false,
      showFolderBrowserDialog: false,
      showLeaveDialog: false,
      showSnackbarBool: false,
      showStaticFields: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      staticFields: [],
      staticSearchAvailableFields: [],
      textractLineItemDataVisible: false,
    }
  },
  computed: {
    addButtonText() {
      let text = 'UPDATE'
      if (this.searchID === 0) {
        text = 'ADD'
      }

      return text
    },
    canSave() { return this.isDirty && this.searchName !== '' && this.indexFormIDs.length !== 0 },
    chooseSubFolderText() { return this.folderName === '' ? 'CHOOSE SUBFOLDER' : `SUBFOLDER: ${this.folderName}` },
    fields() { return this.$store.state.search.combinedFieldSet },
    folderSearchConfigurations() { return this.$store.state.search.folderSearchConfigurations },
    forms() { return this.$store.state.attributeForm.forms },
    selectedSearch() { return this.$store.state.search.editSearch },
    vaults() {
      return this.$store.state.vault.vaultList.map((vault) => {
        return {
          name: vault.vaultName,
          value: vault.vaultID,
        }
      })
    },
  },
  watch: {
    applyToIndexFields() {
      if (this.applyToIndexFields === 'all') {
        this.includeFullTextField = false
        this.fullTextFieldTitle = ''
        this.indexFieldIDs = []
        this.primarySearchFieldIDs = []
        this.staticFields = []
        this.buildSearchFieldsDataTable()
      }
    },
    applyToFolders() {
      if (this.applyToFolders === 'all') {
        this.searchSubfolders = false
        this.searchInFolders = []
        this.buildSearchFoldersDataTable()
      }

      if (this.applyToFolders === 'all' || this.applyToFolders === 'specific') {
        this.folderID = null
        this.folderName = ''
      }
    },
    folderID() {
      if (this.folderID && this.folderID > 0) {
        this.searchInFolders = []
        this.buildSearchFoldersDataTable()
        this.searchInFolders = [this.folderID]
      }
    },
    includeFullTextField() {
      if (this.includeFullTextField === false) {
        this.fullTextFieldTitle = ''
      } else {
        this.applyToFolders = 'specific'
      }
    },
  },
  async created() {
    if (typeof this.searchID === 'undefined'
        || (!this.folderSearchConfigurations || this.folderSearchConfigurations.length < 1)
        || (!this.forms || this.forms.length < 1)
        || (!this.vaults || this.vaults.length < 1)) {
      this.backToSearchSetup()
    }

    this.textractLineItemDataVisible = this.$store.state.systemSettings.featureFlags.textractLineItemDataEnabled

    const me = this
    await this.$store.dispatch('search/loadIndexSearchDetails', this.searchID)
    if (me.selectedSearch) {
      me.searchName = me.selectedSearch.searchName
      me.piiData = me.selectedSearch.piiData
      me.containsSearch = me.selectedSearch.isExact

      const foundCustFolder = me.folderSearchConfigurations.find((element) => {
        return element.id === me.selectedSearch.customizedFolderID
      })

      if (foundCustFolder) {
        me.selectedCustomizeFolder = foundCustFolder
      } else if (me.folderSearchConfigurations.length > 0) {
        // eslint-disable-next-line
        me.selectedCustomizeFolder = me.folderSearchConfigurations[0]
      }

      me.openInNativeFormat = me.selectedSearch.openInNativeFormat
      me.searchInFolders = me.selectedSearch.searchInFolders
      me.indexFormIDs = me.selectedSearch.indexFormIDs
      me.indexFieldIDs = me.selectedSearch.indexFieldIDs
      me.primarySearchFieldIDs = me.selectedSearch.primarySearchFieldIDs
      me.searchSubfolders = me.selectedSearch.searchSubfolders
      me.searchLineItems = me.selectedSearch.searchLineItems
      me.searchLineItemsLabel = me.selectedSearch.searchLineItemsLabel
      me.fullTextFieldTitle = me.selectedSearch.fullTextFieldTitle
      me.staticFields = me.selectedSearch.staticFields
      me.dateOptions = me.selectedSearch.dateOptions

      me.buildSearchFormsDataTable()
      me.createdCombinedFieldSet()
      me.buildSearchFoldersDataTable()

      if (me.indexFieldIDs && me.indexFieldIDs.length > 0) {
        me.applyToIndexFields = 'specific'
      }

      if (me.searchInFolders && me.searchInFolders.length > 0) {
        me.applyToFolders = 'specific'
      }

      if (me.fullTextFieldTitle && me.fullTextFieldTitle !== '') {
        me.includeFullTextField = true
      }

      // If a subfolder was selected for this search previously ...
      if (me.searchInFolders && me.searchInFolders.length === 1 && !me.vaults.some((v) => v.value === me.searchInFolders[0])) {
        me.setSubFolder(me.searchInFolders[0])
      }
    }
  },
  methods: {
    backToSearchSetup() { this.$router.push({ name: 'AdminAttributeSearchList' }) },
    buildDateFieldList() {
      this.dateFieldOptionsAvailableFields = []

      if (!this.flatCombinedFieldSet || this.flatCombinedFieldSet.length === 0) {
        return
      }

      // eslint-disable-next-line prefer-const
      let dateFields = this.flatCombinedFieldSet.filter((f) => f.type === 3)
      if (dateFields && dateFields.length > 0) {
        if (this.applyToIndexFields === 'specific') {
          // If applyToIndexFields === 'specific', the date configuration screen should only include date fields selected in the
          // field selection VasionTable
          const specificFormDateFields = dateFields.filter((f) => this.indexFieldIDs.includes(f.value))
          const allFormDateFields = dateFields.filter((f) => this.primarySearchFieldIDs.includes(f.value))
          dateFields = [...specificFormDateFields, ...allFormDateFields]
          dateFields = Array.from(new Set(dateFields)) // Remove duplicates

          // eslint-disable-next-line
          this.dateFieldOptionsAvailableFields = dateFields.map((f) => {
            if (this.indexFieldIDs.includes(f.value) || this.primarySearchFieldIDs.includes(f.value)) {
              return {
                FieldID: f.value,
                DisplayName: `${f.formName} - ${f.name}`,
                Control: f.type,
              }
            }
          }, this)
        } else {
          this.dateFieldOptionsAvailableFields = dateFields.map((f) => {
            return {
              FieldID: f.value,
              DisplayName: `${f.formName} - ${f.name}`,
              Control: f.type,
            }
          })
        }
      }
    },
    buildSearchFieldsDataTable() {
      if (!this.fields || this.fields.length < 1) {
        this.searchFieldsDataTable = {}
        return
      }
      const data = {
        Columns: {
          Values: [
              '_FieldID_',
              `${$formsLabel} Name`,
              'Field Type',
              'Field Title',
              `_VasionCheckBox_{{Search In Specified ${$formsLabel}}}`,
              `_VasionCheckBox_{{Search In All ${$formsLabelPlural}}}`,
          ],
          ValueType: 'String',
        },
        Rows: {
          Values: [],
          ValueType: 'ApiArray`1',
        },
      }

      this.flatCombinedFieldSet = this.fields.flat()

      data.Rows.Values = this.flatCombinedFieldSet.map((fieldSet) => {
        let specificFormChecked = false
        let allFormsChecked = false

        if (this.indexFieldIDs) {
          specificFormChecked = this.indexFieldIDs.includes(fieldSet.value)
        }
        if (this.primarySearchFieldIDs) {
          allFormsChecked = this.primarySearchFieldIDs.includes(fieldSet.value)
        }

        return {
          Values: [
            fieldSet.value,
            fieldSet.formName,
            this.fieldTypeText(fieldSet.type),
            fieldSet.name,
            { show: true, value: specificFormChecked }, // Search in Specific Form
            { show: true, value: allFormsChecked }, // Search in All Forms
          ],
          ValueType: 'String',
        }
      }, this)

      this.searchFieldsDataTable = data
    },
    buildSearchFoldersDataTable() {
      const data = {
        Columns: {
          Values: [
              '_VasionCheckBox_',
              '_VaultID_',
              'Folder Name',
          ],
          ValueType: 'String',
        },
        Rows: {
          Values: [],
          ValueType: 'ApiArray`1',
        },
      }

      const folders = this.searchInFolders
      data.Rows.Values = this.vaults.map((vault) => {
        let checked = false
        if (folders) {
          checked = folders.includes(vault.value)
        }

        return {
          Values: [
            { show: true, value: checked },
            vault.value,
            vault.name,
          ],
          ValueType: 'String',
        }
      })

      this.searchFoldersDataTable = data
    },
    buildSearchFormsDataTable() {
      const data = {
        Columns: {
          Values: [
              '_VasionCheckBox_',
              '_FormID_',
              `${$formsLabel} Name`,
          ],
          ValueType: 'String',
        },
        Rows: {
          Values: [],
          ValueType: 'ApiArray`1',
        },
      }

      const formIds = this.indexFormIDs
      data.Rows.Values = this.forms.map((form) => {
        let checked = false
        if (formIds) {
          checked = formIds.includes(form.value)
        }

        return {
          Values: [
            { show: true, value: checked },
            form.value,
            form.name,
          ],
          ValueType: 'String',
        }
      })

      this.searchFormsDataTable = data
    },
    buildStaticFieldList() {
      // The static field list should only apply to fields that are not used in the search.
      // Meaning that if "Use All Fields" is checked, then no fields can be used as a Static Field.
      // Or if "Use Specific Fields" is checked, then only those that are not marked as a Primary
      // or Search Field can be used as a Static Field
      // eslint-disable-next-line
      let fieldsToExclude = []

      // eslint-disable-next-line prefer-const
      let dateFields = this.flatCombinedFieldSet
      if (dateFields && dateFields.length > 0) {
        if (this.applyToIndexFields === 'specific') {
          // If applyToIndexFields === 'specific', the date configuration screen should only include date fields selected in the
          // field selection VasionTable
          const specificFormDateFields = dateFields.filter((f) => this.indexFieldIDs.includes(f.value))
          const allFormDateFields = dateFields.filter((f) => this.primarySearchFieldIDs.includes(f.value))
          dateFields = [...specificFormDateFields, ...allFormDateFields]
          dateFields = Array.from(new Set(dateFields)) // Remove duplicates

          // eslint-disable-next-line
          this.fieldsToExclude = dateFields.map((f) => {
            if (this.indexFieldIDs.includes(f.value) || this.primarySearchFieldIDs.includes(f.value)) {
              return f.value
            }
          }, this)
        } else {
          this.fieldsToExclude = dateFields.map((f) => {
            return f.value
          })
        }
      }

      this.staticSearchAvailableFields = !this.flatCombinedFieldSet || this.flatCombinedFieldSet.length === 0 ? [] : this.flatCombinedFieldSet.filter((field) => {
        return !this.fieldsToExclude.includes(field.value)
      })
    },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    createdCombinedFieldSet: async function () {
      const payload = {
        formIds: this.indexFormIDs,
        forms: this.forms,
      }

      await this.$store.dispatch('search/getCombinedFieldSet', payload)
      this.buildSearchFieldsDataTable()
    },
    dateFieldsCancelClick() { this.showDateFields = false },
    dateFieldsOKClick(newDateOptions) {
      this.dateOptions = newDateOptions
      this.showDateFields = false
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    fieldTypeText: function (fieldType) {
      let type = 'n/a'

      switch (fieldType) {
        case 1:
          type = 'Textbox'
          break;
        case 2:
          type = 'Dropdown'
          break;
        case 3:
          type = 'Date'
          break;
        case 6:
          type = 'Number'
          break;
        case 7:
          type = 'URL'
          break;
        case 8:
          type = 'Email'
          break;
        case 9:
          type = 'Checkboxes'
          break;
        case 10:
          type = 'RadioButtons'
          break;
        case 11:
          type = 'Currency'
          break;
        case 12:
          type = 'Signature Field'
          break;
        case 13:
          type = 'Text Area'
          break;
        case 14:
          type = 'Address'
          break;
        default:
          type = 'n/a'
          break;
      }

      return type
    },
    folderModalOK() {
      if (this.$store.state.common.selectedFolder
        && this.$store.state.common.selectedFolder.isSelected === true) {
        this.folderID = this.$store.state.common.selectedFolder.item.FolderId
        this.folderName = this.$store.state.common.selectedFolder.item.Name
      } else {
        this.folderID = null
        this.folderName = ''
      }

      this.toggleBrowseFolderDialog()
    },
    handleSearchFieldRowCheckChange(checkedBoxes) {
      if (checkedBoxes && checkedBoxes.length > 0) {
        // Handle "Search In Specified Form" checkboxes
        const specificFormList = checkedBoxes.filter((b) => b.Values[4].value === true) // "Search In Specified Form" is stored in the 4th element in the searchFieldsDataTable
        if (specificFormList && specificFormList.length > 0) {
          const specificFormsArray = specificFormList.map((box) => {
            return box.Values[0] // The ID value is stored in the 1st element in the searchFieldsDataTable
          })
          this.indexFieldIDs = Array.from(new Set(specificFormsArray)) // Set removes duplicate values
          this.setIsDirty()
        }

        // Handle "Search In All Forms" checkboxes
        const allFormsList = checkedBoxes.filter((b) => b.Values[5].value === true) // "Search In Specified Form" is stored in the 5th element in the searchFieldsDataTable
        if (allFormsList) {
          const allFormsArray = allFormsList.map((box) => {
            return box.Values[0] // The ID value is stored in the 1st element in the searchFieldsDataTable
          })
          this.primarySearchFieldIDs = Array.from(new Set(allFormsArray)) // Set removes duplicate values
        }
      } else {
        this.indexFieldIDs = []
        this.primarySearchFieldIDs = []
      }
    },
    handleSearchFolderRowCheckChange(checkedBoxes) {
      if (checkedBoxes && checkedBoxes.length > 0) {
        this.searchInFolders = checkedBoxes.map((box) => {
          return box.Values[1] // The ID value is stored in the 2nd element in the searchFoldersDataTable
        })
        this.setIsDirty()
      } else {
        this.searchInFolders = []
      }
    },
    handleSearchFormRowCheckChange(checkedBoxes) {
      this.setIsDirty()
      if (checkedBoxes && checkedBoxes.length > 0) {
        this.indexFormIDs = checkedBoxes.map((box) => {
          return box.Values[1] // The ID value is stored in the 2nd element in the searchFormsDataTable
        })
      } else {
        this.indexFormIDs = []
        this.indexFieldIDs = []
        this.primarySearchFieldIDs = []
        this.$store.dispatch('search/resetCombinedFieldList')
      }

      this.createdCombinedFieldSet()
    },
    launchDateFields() {
      if (!this.showDateFields) {
        this.buildDateFieldList()
        this.showDateFields = !this.showDateFields
      }
    },
    launchStaticFields() {
      if (!this.showStaticFields) {
        this.buildStaticFieldList()
        this.showStaticFields = !this.showStaticFields
      }
    },
    async saveIndexSearch() {
      if (!this.validateBeforeSave()) {
        return
      }

      const payload = {
        searchID: this.searchID,
        searchName: this.searchName,
        piiData: this.piiData,
        isExact: this.containsSearch,
        customizedFolderID: this.selectedCustomizeFolder && this.selectedCustomizeFolder.id ? this.selectedCustomizeFolder.id : 0,
        openInNativeFormat: this.openInNativeFormat,
        searchInFolders: this.searchInFolders,
        indexFormIDs: this.indexFormIDs,
        indexFieldIDs: this.indexFieldIDs,
        primarySearchFieldIDs: this.primarySearchFieldIDs,
        searchSubfolders: this.searchSubfolders,
        searchLineItems: this.searchLineItems,
        searchLineItemsLabel: this.searchLineItems ? this.searchLineItemsLabel : '',
        fullTextFieldTitle: this.fullTextFieldTitle,
        staticFields: this.staticFields,
        dateOptions: this.dateOptions,
      }

      const result = await this.$store.dispatch('search/saveIndexSearch', payload)

      if (result.Value == "Success") {
        this.snackbarImage = true
        this.snackbarTitle = 'Save Successful'
        this.snackbarSubTitle = `Successfully saved: "${this.searchName}"`
        this.showSnackbarBool = true

        this.isDirty = false
        this.backToSearchSetup()
      } else {
        this.snackbarImage = false
        this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = result.Value
      }
    },
    setIsDirty(){
      this.isDirty = true
    },
    async setSubFolder(folderId) {
      let currentFolder
      // This is supposedly a subfolder. So, to begin, try to go up one folder level ...
      const parentFolderId = await this.$store.dispatch('vault/getParentFolderID', folderId)
      if (parentFolderId) {
        // If we got a parent folder id, now go about getting all of it's children
        let childFolders = []
        const payload = {
          parentFolderId: parentFolderId,
          enforceUserSecurity: false,
          groupByStorageType: false,
        }
        const response = await this.$store.dispatch('common/getChildFolders', payload)
        if (response?.data && response.data.FolderList) {
          // If we got child folders, now find the specific one we are after ...
          childFolders = response.data.FolderList.Values
          if (childFolders && childFolders.length > 0) {
            currentFolder = childFolders.find((f) => f.FolderId === folderId && f.ParentId === parseInt(parentFolderId, 10))
            if (currentFolder) {
              this.folderID = folderId
              this.folderName = currentFolder.Name
              this.applyToFolders = 'sub'
            }
          }
        }
      }
    },
    staticFieldsCancelClick() { this.showStaticFields = false },
    staticFieldsOKClick(newStaticCriteria) {
      this.staticFields = newStaticCriteria
      this.showStaticFields = false
    },
    toggleBrowseFolderDialog() { this.showFolderBrowserDialog = !this.showFolderBrowserDialog },
    toggleIncludeFullTextField() { this.includeFullTextField = !this.includeFullTextField },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    togglePiiData() { this.piiData = !this.piiData },
    toggleSearchLineItems() { this.searchLineItems = !this.searchLineItems },
    toggleSearchOnlyInSubfolders() { this.searchSubfolders = !this.searchSubfolders },
    validateBeforeSave() {
      let errorText = ''

      if (!this.searchName || this.searchName === '') {
        errorText += '- A search name must be provided.\n'
      } else if (this.searchName.trim() === '') {
        errorText += '- Search name cannot only include spaces.\n'
      }

      if (!this.indexFormIDs || (this.indexFormIDs && this.indexFormIDs.length < 1)) {
        errorText += `- At least one ${$formsLabel.toLowerCase()} must be selected.\n`
      }

      if (this.includeFullTextField === true
          && (!this.searchInFolders || (this.searchInFolders && this.searchInFolders.length < 1))
        ) {
        errorText += '- If "Include Additional Full Text Search" is enabled, at least one folder must be selected.\n'
      }

      if (this.applyToIndexFields === 'specific'
          && (!this.indexFieldIDs || (this.indexFieldIDs && this.indexFieldIDs.length < 1))
          && (!this.primarySearchFieldIDs || (this.primarySearchFieldIDs && this.primarySearchFieldIDs.length < 1))
        ) {
        errorText += '- If "Apply on specific fields" is selected, at least one field must also be selected.\n'
      }

      if ((this.applyToFolders === 'specific' || this.applyToFolders === 'sub')
          && (!this.searchInFolders || (this.searchInFolders && this.searchInFolders.length < 1))
        ) {
        errorText += '- If "Apply on specific folders" or "Choose Subfolder" is selected, at least one folder must also be selected.\n'
      }

      if (this.includeFullTextField === true && this.fullTextFieldTitle === '') {
        errorText += '- If "Include Additional Full Text Search" is enabled, a full text search value must also be provided.'
      }

      if (errorText) {
        this.snackbarTitle = 'VALIDATION ERROR'
        this.snackbarSubTitle = errorText
        this.snackbarImage = false
        this.showSnackbarBool = true

        return false
      } else {
        return true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .search-name {
    margin-right: 16px;
  }

  .w100 {
    width: 100%;
  }

  #index-search-setup {
    display: block;
    float: left;
    overflow: auto;
    height: calc(100vh - 114px);

    & .setup-row {
      display: block;
      float: left;
      width: 100%;
      margin: 6px 0;

      & #pii-data {
        margin: 12px;
      }

      & #table-label-pii {
        width: 100%;
        height: 35px;
        margin: 5px 0px 3px 0px;

        & #table-label {
          display: inline-block;
          margin-top: 10px;
          height: 12px;
          font-family: $font-family-medium, 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
          font-size: 12px;
          font-weight: 500;
          color: $grey-400;
          margin-bottom: 2px;
          bottom: 4px;
        }
      }
    }
  }

  .inner-div {
    margin: 10px 16px 0 16px;
  }

  .iss-vasion-checkbox {
    display: block;
    float: right;
    margin: 6px 6px 0 6px;
  }

  #section-headline {
    @include Headline;
  }

  .action-buttons {
    float: right;
  }

  #cancel-button {
    border-left: thin solid $grey-300;
  }

  #search-forms, #search-fields, #search-folders {
    max-height: 300px;
    overflow: auto;
  }

  .back-arrow {
    padding: 5px 5px 0 0;
  }

  .title-input {
    padding-top: 10px;
  }

  #apply-folders, #sub-folders {
    width: 50%; float: left;

    & > div {
      width: 100%;

    }
  }

  #apply-fields, #full-text, #line-items {
    width: 33%;
    padding: 5px 10px;

    & > div {
      width: 100%;
    }
  }

  .two-columns {
    width: 50% !important;
    padding: 5px 0px !important;
  }

  hr {
    border-width: 1px;
    border-color: $grey-100;
    border-style: solid;
    margin-top: 0;
  }

  .margin-bottom-30 {
    margin-bottom: 30px !important;
  }

  .align-right {
    text-align: right;
  }

  .folder-dialog-browse {
    width: 400px;
    height: 300px;
    overflow: auto;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
    color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
    border-color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
    background-color: $secondary !important;
  }

  ::v-deep   .widerCheckboxColumn {
    max-width: 120px;
    width: 120px;
  }
</style>
